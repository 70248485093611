import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import './PortfolioSection.css';

const PortfolioSection = () => {
    const [activeFilter, setActiveFilter] = useState('all');

    const portfolioItems = [
        { id: 1, title: 'Website Project 1', category: 'websites', image: 'path/to/image1.jpg' },
        { id: 2, title: 'Music Project 1', category: 'music', image: 'path/to/image2.jpg' },
        { id: 3, title: 'Business Project 1', category: 'business', image: 'path/to/image3.jpg' },
        { id: 4, title: 'Website Project 2', category: 'websites', image: 'path/to/image4.jpg' },
        { id: 5, title: 'Music Project 2', category: 'music', image: 'path/to/image5.jpg' },
        // Add more portfolio items as needed
    ];

    const filteredItems = activeFilter === 'all'
        ? portfolioItems
        : portfolioItems.filter(item => item.category === activeFilter);

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1
    };

    return (
        <section className="portfolio" id="portfolio">
            <h2>Portfolio</h2>
            <div className="portfolio-filters">
                <button onClick={() => setActiveFilter('all')} className={activeFilter === 'all' ? 'active' : ''}>All</button>
                <button onClick={() => setActiveFilter('websites')} className={activeFilter === 'websites' ? 'active' : ''}>Websites</button>
                <button onClick={() => setActiveFilter('music')} className={activeFilter === 'music' ? 'active' : ''}>Music</button>
                <button onClick={() => setActiveFilter('business')} className={activeFilter === 'business' ? 'active' : ''}>Business</button>
            </div>

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {filteredItems.map(item => (
                    <div key={item.id} className="portfolio-item">
                        <img src={item.image} alt={item.title} />
                        <h3>{item.title}</h3>
                    </div>
                ))}
            </Masonry>
        </section>
    );
};

export default PortfolioSection;
