import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ResumeSection from './components/ResumeSection';
import PortfolioSection from './components/PortfolioSection';
import Footer from './components/Footer';
import './styles.css';

function App() {
    return (
        <div>
            <Navbar />
            <HeroSection />
            <AboutSection />
            <ResumeSection />
            <PortfolioSection />
            <Footer />
        </div>
    );
}

export default App;
