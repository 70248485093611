import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { Link } from 'react-scroll';

const Footer = () => {
    return (
        <footer>
            <p>&copy; 2024 Gene's Resume</p>
            <Link to="hero" smooth={true} duration={500} className="arrow-up">
                <FaArrowUp />
            </Link>
        </footer>
    );
}

export default Footer;
