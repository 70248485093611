import React from 'react';

const AboutSection = () => {
    return (
        <section className="about" id="about">
            <h2>About Me</h2>
            <p>
                With over a decade of experience in the IT industry, I have honed my skills as a versatile and results-driven IT Systems Engineer. My journey began with foundational roles in IT support, where I developed a strong technical foundation in end-user support, server administration, and network management. Over the years, I've taken on increasingly complex challenges, leading significant projects in automation, disaster recovery, and infrastructure management.
            </p>
            <p>
                Currently, I specialize in driving automation and efficiency within IT operations, having successfully reduced manual tasks by 30% through innovative workflows. My expertise extends to integrating and managing complex systems, including cloud and identity management solutions such as OKTA and Azure Entra. I've also led the migration of critical infrastructure to hybrid cloud environments, ensuring business continuity and compliance with industry standards.
            </p>
            <p>
                My passion for technology is matched by my commitment to mentorship and leadership. As a senior escalation point, I’ve guided IT support teams, streamlining processes, and improving overall service quality. I thrive in cross-functional teams, driving projects that not only save costs but also enhance operational efficiency and user satisfaction.
            </p>
            <p>
                I am constantly exploring new ways to leverage technology to solve complex problems and improve business outcomes, always with a focus on delivering measurable results.
            </p>
        </section>
    );
}

export default AboutSection;
