import React from 'react';

const ResumeSection = () => {
    return (
        <section className="resume" id="resume">

           {/* Work Experience Section */}
           <div className="resume-subsection">
                <h3 className="section-title">Work</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>IT Systems Engineer</h4>
                        <p>Disclosed · Jan 2022 - Present · 2 yrs 8 mos</p>
                        <ul>
                            <li>Automation & Efficiency: Developed and implemented automation workflows that reduced manual tasks by 30%, integrated 40+ SAML 2.0 applications with OKTA and Azure Entra, ensuring PCI/SOX compliance.</li>
                            <li>Disaster Recovery & Infrastructure Management: Led the migration of on-premises VMware ESXi environments to hybrid cloud solutions, designed and implemented disaster recovery plans using Veeam and Azure Blob storage, ensuring 99.99% uptime.</li>
                            <li>Project Leadership: Spearheaded a cross-functional team to optimize software licensing, resulting in a $750,000 annual cost reduction. Promoted from Systems Administrator to IT Support Engineer within 12 months.</li>
                            <li>Technical Support: Acted as the senior escalation point for IT support teams, mentoring junior engineers and streamlining the escalation process.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>IT Support Specialist</h4>
                        <p>Malwarebytes · Full-time | Feb 2020 - Jan 2022 · 2 yrs | Sunnyvale, California, United States</p>
                        <ul>
                            <li>Comprehensive IT Support: Delivered cross-platform support for over 500 users, including C-level executives, increasing user satisfaction by 25%.</li>
                            <li>Cloud & Identity Management: Managed user lifecycles across Azure AD, O365, and Okta, optimizing identity management processes and reducing login issues by 20%.</li>
                            <li>Virtual Environment Management: Managed and supported VMware environments and MDM platforms (JAMF, Intune), leading a SaaS initiative that improved software deployment efficiency by 40%.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>System Support Analyst via HCL Technologies</h4>
                        <p>LinkedIn · Contract | Nov 2018 - Jan 2020 · 1 yr 3 mos | Sunnyvale, CA</p>
                        <ul>
                            <li>End-User Support: Provided L2 support for a mixed environment of Mac/Windows/Linux, reducing incident resolution time by 15% through automation and process optimization.</li>
                            <li>Device & System Lifecycle Management: Led the imaging, deployment, and lifecycle management of over 1,000 devices, utilizing scripting (Bash) to automate routine tasks.</li>
                            <li>Advanced Troubleshooting: Collaborated with engineering teams on Go-Live projects, beta testing, and resolving complex technical issues, contributing to a 99.8% user satisfaction rate.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>IT Field Services Technician II</h4>
                        <p>Corning Incorporated · Full-time | Jan 2016 - Oct 2018 · 2 yrs 10 mos | Union City, California</p>
                        <ul>
                            <li>User Support & Automation: Supported 1,200+ end-users across diverse platforms, implementing automation scripts that reduced IT service request resolution times by 20%.</li>
                            <li>Server & Network Management: Managed a variety of server environments, including Windows Server, Active Directory, VMware, and Linux, ensuring optimal performance and security.</li>
                            <li>Process Improvement: Championed continuous improvement by developing automated processes for server deployments, backups, and user management, significantly reducing manual workloads.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Technician</h4>
                        <p>Circle of Help Foundation · Full-time | Jul 2010 - Sep 2012 · 2 yrs 3 mos | Greater Los Angeles Area</p>
                        <ul>
                            <li>Identity Management: Active Directory.</li>
                            <li>Server Administration: Windows Servers, Domain Controllers, DNS, Printer Server, MSSQL, File Server, Peachtree Server.</li>
                            <li>Virtualization Administration: vSphere.</li>
                            <li>Network Administration: Web Filter Server.</li>
                            <li>Provided level 1 to level 3 end-user support, responded to daily technical issues, and maintained company assets including laptops, desktops, Windows OS, Apple OSX, iOS, and Android devices.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Analyst</h4>
                        <p>Center for Elders Independence · Full-time | Jun 2009 - Sep 2009 · 4 mos | Oakland, California</p>
                        <ul>
                            <li>Provided level 1 to level 2 end-user support, retrieved and repaired PCs, installed new hardware, maintained updates, and imaged new OS.</li>
                            <li>Assisted in deploying new PC machines, servers, and a new phone system during an infrastructure upgrade, and responded to IT support tickets.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Assistant</h4>
                        <p>Diablo Magazine · Internship | Aug 2007 - Jun 2008 · 11 mos | Walnut Creek, California</p>
                        <ul>
                            <li>Provided level 1 end-user support, maintained asset management system, responded to technical issues, and managed Active Directory.</li>
                            <li>Supported Windows OS and Apple OSX environments.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Assistant</h4>
                        <p>ACALANES UNION HIGH SCHOOL DISTRICT · Internship | Aug 2007 - Jan 2008 · 6 mos | Walnut Creek, California, United States</p>
                        <ul>
                            <li>Assisted IT field services with level 1 support at Las Lomas High School.</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Certifications Section */}
            <div className="resume-subsection">
                <h3 className="section-title">Certifications</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>Certification Name 1</h4>
                        <p>Issuing Organization, Date</p>
                    </div>
                    <div className="resume-item">
                        <h4>Certification Name 2</h4>
                        <p>Issuing Organization, Date</p>
                    </div>
                </div>
            </div>

            {/* Education Section */}
            <div className="resume-subsection">
                <h3 className="section-title">Education</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>Degree Name 1</h4>
                        <p>Institution Name, Graduation Date</p>
                    </div>
                    <div className="resume-item">
                        <h4>Degree Name 2</h4>
                        <p>Institution Name, Graduation Date</p>
                    </div>
                </div>
            </div>

            {/* Projects Section */}
            <div className="resume-subsection">
                <h3 className="section-title">Projects</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>Project Name 1</h4>
                        <p>Brief description of the project and your role.</p>
                    </div>
                    <div className="resume-item">
                        <h4>Project Name 2</h4>
                        <p>Brief description of the project and your role.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResumeSection;
